export const CDP_EVENTS = {
  SCREENER_OUTPUT_ERROR: 'SCREENER_OUTPUT_ERROR',
  SCREENER_OUTPUT: 'SCREENER_OUTPUT',
  SCREENER_SECOND_QUESTION: 'SCREENER_SECOND_QUESTION',
  SITE_SELECTION_TOGGLE: 'SITE_SELECTION_TOGGLE',
  SITE_SELECTION_TOGGLE_ERROR: 'SITE_SELECTION_TOGGLE_ERROR',
  SITE_SELECTION_SITE_SELECTED: 'SITE_SELECTION_SITE_SELECTED',
  SITE_SELECTION_SITE_SELECTED_ERROR: 'SITE_SELECTION_SITE_SELECTED_ERROR',
  SITE_SELECTION_NO_SITE_CONVENIENT: 'SITE_SELECTION_NO_SITE_CONVENIENT',
  SITE_SELECTION_NO_SITE_CONVENIENT_ERROR: 'SITE_SELECTION_NO_SITE_CONVENIENT_ERROR',
  SITE_SELECTION_NO_SITE_AVAILABLE_MAX_CAP: 'SITE_SELECTION_NO_SITE_AVAILABLE_MAX_CAP',
  SITE_SELECTION_NO_SITE_AVAILABLE: 'SITE_SELECTION_NO_SITE_AVAILABLE',
  SITE_SELECTION_KIELEMENT_POPUP: 'SITE_SELECTION_KIELEMENT_POPUP',
  SITE_SELECTION_LIFELINK_POPUP: 'SITE_SELECTION_LIFELINK_POPUP',
  SITE_SELECTION_LIFELINK_POPUP_CONTINUE: 'SITE_SELECTION_LIFELINK_POPUP_CONTINUE',
  SITE_SELECTION_LIFELINK_POPUP_CANCELLED: 'SITE_SELECTION_LIFELINK_POPUP_CANCELLED',
  CONTACT_FORM_SUBMIT: 'CONTACT_FORM_SUBMIT',
  CONTACT_FORM_ERROR: 'CONTACT_FORM_ERROR',
  HOME_PAGE_LOAD: 'HOME_PAGE_LOAD',
  SUCCESS_PAGE_FAILURE: 'SUCCESS_PAGE_FAILURE',
  SUCCESS_PAGE_LOADED: 'SUCCESS_PAGE_LOADED',
  MY_PROFILE_EMAIL_LINK_CLICK: 'MY_PROFILE_EMAIL_LINK_CLICK',
  UNSUBSCRIBE_CLICK: 'UNSUBSCRIBE_CLICK',
  RESUBSCRIBE_CLICK: 'RESUBSCRIBE_CLICK',
  UPDATE_EMAIL: 'EMAIL_UPDATE',
  ADD_COMMUNITY: 'ADD_COMMUNITY',
  REMOVE_COMMUNITY: 'REMOVE_COMMUNITY',
  IDLE_SESSION_TIMEOUT: 'IDLE_SESSION_TIMEOUT',
  LOGOUT: 'LOGOUT',
  CDP_API_CONTACTINFO_FAILURE: 'CDP_API_CONTACTINFO_FAILURE',
  CDP_API_CONTACTINFO_SUCCESS: 'CDP_API_CONTACTINFO_SUCCESS',
  API_FAILURE: 'API_FAILURE',
  CDP_API_FETCHPROFILE_SUCCESS: 'CDP_API_FETCHPROFILE_SUCCESS',
  CDP_API_FETCHPROFILE_FAILURE: 'CDP_API_FETCHPROFILE_FAILURE',
  CONTACTFORM_EXPERIENCE_SUCCESS: 'CONTACTFORM_EXPERIENCE_SUCCESS',
  CONTACTFORM_EXPERIENCE_FAILURE: 'CONTACTFORM_EXPERIENCE_FAILURE',
  BASE_PROFILE_UPDATED: 'BASE_PROFILE_UPDATED',
};

export const CONTACT_INFO_URLS = {
  ADULT: '/adult',
  MINOR: '/minor',
  CAREGIVER: '/caregiver',
};

export const ZIPCODE_FIELDS = {
  ADULT_PATIENT_ZIPCODE: 'Zipcode',
  MINOR_DELEGATE_ZIPCODE: 'ZipCode',
  CAREGIVER_PATIENT_ZIPCODE: 'ParticipantZipcode',
  CAREGIVER_DELEGATE_ZIPCODE: 'CaregiverZipCode',
};

export const JOURNEY_STEP = {
  CONTACT_INFO: 'contact-info',
  SCREENER: 'screener',
  SITESELECTION: 'site-selection',
};

export const JOURNEY_STATUS = {
  PARTIAL: 'Partial',
  COMPLETED: 'Complete',
};

export const DEFAULT_SCREENER_TYPE = 'Alchemer';

export const SCREENER_STATUS = {
  QUALIFIED: 'qualified',
  DISQUALIFIED: 'disqualified',
};
export const NSC_STATUS = {
  NOSITECONVENIENT: 'No-site-convenient',
  SITENOTCONVENIENT: 'Site-not-convenient',
};

export const JOURNEYS = {
  JOURNEY_01: 'Screener-SiteSelection-ContactInfo',
  JOURNEY_02: 'Screener-ContactInfo-SiteSelection',
  JOURNEY_03: 'ContactInfo-Screener-SiteSelection',
} as const;

export const JOURNEYS_ID_MAPPER = {
  '{C4C532DC-F337-4A9B-B5F7-D86700C899EA}': 'Screener-SiteSelection-ContactInfo',
  '{1E2A09A7-AE87-4005-BF43-1C33D9D488B0}': 'Screener-ContactInfo-SiteSelection',
  '{BB766A4A-E89F-4508-8B0A-3B19A858A1D5}': 'ContactInfo-Screener-SiteSelection',
} as const;

export const DEFAULT_ZIPCODE_ERROR_MESSAGE = { value: 'Please enter a valid zipcode' };
export const DEFAULT_ZIPCODE_REQUIRED_MESSAGE = { value: 'Please enter a zipcode' };
export const DEFAULT_ZIPCODE_INVALID_MESSAGE = { value: 'Invalid zipcode format' };

export enum CAROUSEL_VARIATION {
  VARIATION1 = 'Variation1',
  VARIATION2 = 'Variation2',
  VARIATION3 = 'Variation3',
  VARIATION4 = 'Variation4',
}

export enum CAROUSEL_TYPE {
  IMAGE = 'Image',
  SITECORE = 'Sitecore',
  YOUTUBE = 'Youtube',
  VIMEO = 'Vimeo',
}
export const VIEW_ALL_OPTION_VALUE = 'viewall';

export const STUDY_LOCATION_VARIATIONS = {
  VARIATION_ONE: 'Variation1',
  VARIATION_TWO: 'Variation2',
  VARIATION_THREE: 'Variation3',
};

export enum PATIENT_TYPE {
  ADULT = '{ED808045-0230-4744-932C-881A018120FC}',
  CAREGIVER = '{4D70D876-4C34-4EC3-A64C-4250DB9A1042}',
  MINOR = '{D552592D-2140-48BE-9D6E-1CDEEA1A3981}',
}
export const EXPLORE_PROFILE = 'Explore Profile';
export const EMAIL_ADDRESS_RESEND = 'EmailAddressResend';
export const PROFILE_VERIFY_TOKEN_KEY = 'myProfileVerificationToken';
export const PATIENT_TYPE_ADULT = 'Potential Participant';
export enum EMAIL_TEMPLATES {
  REGISTRATION_WELCOME = 'Registration-Welcome',
  MANAGE_PROFILE = 'Manage-Profile',
  HCP_REGISTRATION = 'HCP_Registration',
}

export enum EMAIL_TEMPLATE {
  REGISTRATION_WELCOME = 'Registration-Welcome',
  MANAGE_PROFILE = 'Manage-Profile',
  HCP_REGISTRATION = 'HCP_Registration',
}

export const EMAIL_SOURCE = 'CR';
export const SITE_SOURCE = 'CR';
