/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LinkField, RichText, Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { JSSLink } from '../../../components/shared/Links/JSSLink.styles';
import { StyledDivider, StTextContainer } from '../Subscribe.styles';
import { StDTPGridContainer } from '../../../components/shared/Container/Container.styles';
import { FormInput } from '../../../components/shared/FormElements/FormInput/FormInput';
import { SubscribeProps } from '../Subscribe.types';
import { post } from '../../../lib/utils/axiosFetcher';
import { CDP_EVENTS } from '../../../../src/constants';
import * as cdpService from '../../../services/CdpService';
import { hideLoader, showLoader } from '../../../features/loaderSlice';
import { useDispatch } from 'react-redux';
import { setCookie } from 'cookies-next';
import {
  ENROLLMENT_EXPIRY_TIME_FALLBACK,
  getCookieExpiryTime,
} from '../../../lib/utils/enrollmentUtil';
import { CR_SITE } from '../../../lib/constant';

type CheckboxInputProps = ControllerRenderProps & CheckboxProps & any;

const Unsubscribe = (props: SubscribeProps): JSX.Element => {
  const theme = useTheme();
  const router = useRouter();
  const isMobile = useMediaQuery(theme.breakpoints.down(500));
  const { sitecoreContext } = useSitecoreContext();
  const siteName = sitecoreContext?.site?.name;
  const dispatch = useDispatch();

  const {
    control,
    trigger,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useForm({ mode: 'all' });
  const {
    heading,
    content,
    content1,
    link,
    buttonVariant,
    emailInputName,
    emailLabel,
    tooltipLabel,
    emailPlaceholder,
    isEmailRequired,
    isConsentRequired,
    requiredEmailValidationMessage,
    emailRegex,
    invalidInputEmailValidationMessage,
    serverSideEmailValidationError,
    consentCheckboxName,
    consentDescription,
    consentRequiredValidationMessage,
  } = props?.fields || {};
  const CheckboxInput: React.FC<CheckboxInputProps> = ({ value, ...rest }: CheckboxInputProps) => {
    return (
      <Checkbox
        checked={!!value}
        {...rest}
        sx={{
          alignSelf: 'flex-start',
          padding: '0 9px',
          '.MuiCheckbox-root': {
            color: 'black',
            backgroundColor: 'black',
          },
        }}
        className="checkbox"
        value="consent"
        color="primary"
      />
    );
  };
  useEffect(() => {
    if (router?.isReady) {
      dispatch(showLoader());
      const unsubscribeToken = router.query?.uToken as string;
      unsubscribePatient(unsubscribeToken);
      dispatch(hideLoader());
    }
  }, [router?.isReady]);

  const unsubscribePatient = async (unsubscribeToken: any) => {
    try {
      cdpService.event(CDP_EVENTS.UNSUBSCRIBE_CLICK, {});
      const ppId = await post('/Send/Unsubscribe', {
        unsubscribeToken: unsubscribeToken,
        siteSettingsId: sitecoreContext?.siteSettingsId,
        language: sitecoreContext?.language,
      });
      if (ppId !== undefined && ppId !== null && ppId !== '') {
        setCookie('bx_guest_ref', ppId?.data?.guestRef, {
          expires: getCookieExpiryTime(ENROLLMENT_EXPIRY_TIME_FALLBACK),
          secure: true,
        });
        cdpService.identityEvent(ppId?.data?.email);
        if (siteName === CR_SITE) {
          cdpService.event(CDP_EVENTS.BASE_PROFILE_UPDATED, {
            reference: 'email_unsubscribe_update',
          });
        }
      }
    } catch {}
  };
  const handleResubscribe = async (e: any) => {
    dispatch(showLoader());
    e.preventDefault();
    const isFormValid = await trigger();
    if (isFormValid) {
      try {
        const email = getValues('email-input' as never);
        cdpService.event(CDP_EVENTS.RESUBSCRIBE_CLICK, {});
        const isValid = await post('/Send/Resubscribe', {
          unsubscribeToken: router?.query?.uToken as string,
          emailAddress: email,
          siteSettingsId: sitecoreContext?.siteSettingsId,
          language: sitecoreContext?.language,
        });
        if (isValid) {
          if (siteName === CR_SITE) {
            cdpService.event(CDP_EVENTS.BASE_PROFILE_UPDATED, {
              reference: 'email_resubscribe_update',
            });
          }
          router.push(`${link?.value?.href}`);
        } else {
          setError('emailValidation', {
            type: 'server',
          });
        }
      } catch {
        setError('emailValidation', {
          type: 'server',
        });
      }
    }
    dispatch(hideLoader());
  };
  if (!props?.fields) return <></>;
  return (
    <StDTPGridContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        rowGap="2rem"
        sx={{ marginBlock: '2rem' }}
      >
        <Typography
          variant="h1"
          data-testid="title"
          textAlign="center"
          sx={{
            display: 'flex',
            color: theme.palette.themeColor.main,
            fontSize: '2rem',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '2.5rem',
            [theme.breakpoints.down('md')]: {
              fontSize: '2rem',
            },
          }}
        >
          <Text field={heading} />
        </Typography>
        <StyledDivider />
        <StTextContainer>
          <Box
            data-testid="content"
            textAlign="center"
            sx={{
              color: (theme: any) => theme.palette.themeColor.main,
              fontSize: '1.5rem',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '2rem',
            }}
          >
            <RichText field={content} />
          </Box>
        </StTextContainer>
        <StTextContainer>
          <Box
            data-testid="content1"
            textAlign="center"
            sx={{
              color: (theme: any) => theme.palette.common.black,
              fontSize: '1.125rem',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '1.5rem',
            }}
          >
            <RichText field={content1} />
          </Box>
        </StTextContainer>
        <Box
          sx={{
            width: isMobile ? '100%' : '360px',
            alignSelf: 'center',
          }}
        >
          {/* @ts-ignore */}
          <Box
            sx={{
              '.MuiInputBase-root': {
                color: `${theme.palette.common.black} !important`,
              },
            }}
          >
            <FormInput
              label={emailLabel}
              name={emailInputName?.value as string}
              toolTip={tooltipLabel?.value}
              placeholder={emailPlaceholder?.value as string}
              control={control}
              errors={errors}
              rules={{
                required: isEmailRequired?.value ? requiredEmailValidationMessage?.value : ``,
                validate: async (value: string) => {
                  clearErrors('emailValidation');
                  const regEx = RegExp(emailRegex?.value);
                  return regEx.test(value) ? true : invalidInputEmailValidationMessage?.value ?? '';
                },
              }}
            />
          </Box>
          <Box sx={{ color: '#E20000', fontSize: '0.8125rem' }}>
            {errors?.emailValidation &&
              (serverSideEmailValidationError?.value ??
                'You have entered an incorrect email address')}
          </Box>
        </Box>
        <Box sx={{ width: isMobile ? '100%' : '360px', alignSelf: 'center' }}>
          <Controller
            control={control}
            name={consentCheckboxName?.value as string}
            rules={{
              required: {
                value: isConsentRequired?.value || false,
                message: consentRequiredValidationMessage?.value || '',
              },
            }}
            render={({ field }) => (
              <FormControl
                sx={{
                  color: (theme: any) => theme.palette.common.black,
                }}
              >
                <FormControlLabel
                  className="checkboxLabel"
                  control={<CheckboxInput {...field} />}
                  label={<RichText className="description" field={consentDescription} />}
                />
              </FormControl>
            )}
          />
          {errors && errors[consentCheckboxName?.value as string] && (
            <Typography
              sx={{
                color: theme.palette.error.main,
                fontWeight: 400,
                fontSize: '0.8125rem',
                lineHeight: '1.25rem',
                marginLeft: '30px',
              }}
            >
              {`${errors[consentCheckboxName?.value as string]?.message}`}
            </Typography>
          )}
        </Box>
        <JSSLink
          data-testid="link"
          className={'unsubscribe-btn btn'}
          variant={buttonVariant?.value || 'contained'}
          field={link as LinkField}
          onClick={(e: any) => {
            clearErrors('emailValidation');
            handleResubscribe(e);
          }}
          sx={{
            paddingInline: '22px',
            minWidth: 'auto',
            [theme.breakpoints.down('sm')]: {
              paddingBlock: '6px',
              width: '100%',
            },
          }}
        />
      </Box>
    </StDTPGridContainer>
  );
};

export default Unsubscribe;
