/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { EPR_TYPE, ISiteMngData } from '../types';
import { Roles, formatSitesForTranslation, saveSites, translateSites, getSites } from '../helpers';
import TranslateModal from '../TranslateModal';
import { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

interface IProps {
  data: ISiteMngData[];
  rowSelection: any;
  setRowSelection: any;
  editedUids: string[];
  setEditedUids: React.Dispatch<React.SetStateAction<string[]>>;
  selectedEprName: string;
  setData: (newData: ISiteMngData[]) => void;
  setCloneData: (newData: ISiteMngData[]) => void;
  role: string;
  setIsLoading: any;
}

const SOURCE_SMA = 'SMA';

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const FooterActions: React.FC = (props: IProps) => {
  const rowSelection = props.rowSelection;
  const editedUids = props.editedUids;
  const [isTranslateOpen, setIsTranslateOpen] = useState<boolean>(false);
  const selectedEpr = props.selectedEprName || '';
  const selectedFilters = useSelector((state: any) => state?.sma?.selectedFilters);
  const selectedProtocol = useSelector((state: any) => state?.sma?.selectedProtocol);
  const selectedProtocolItemId = useSelector((state: any) => state?.sma?.selectedProtocolItemId);
  const fieldOptions = useSelector((state: any) => state?.sma?.fieldOptions);
  const eprFieldOptions = useSelector((state: any) => state?.sma?.eprFieldOptions);
  const userName = useSelector((state: any) => state?.sma?.userName);

  /**
   * Saves selected rows. If no Rows are selected all edited rows are saved
   */
  const onSaveClick = async (isPublish: boolean) => {
    if (editedUids.length > 0) {
      let uidsToSave: string[] = [];
      const isRowsSelected = Object.keys(rowSelection).length > 0;
      if (isRowsSelected) {
        // Filter only edited ids
        const selectedRowIds = Object.keys(rowSelection);
        selectedRowIds.forEach((selectedId) => {
          if (editedUids.includes(selectedId)) {
            uidsToSave.push(selectedId);
          }
        });
      } else {
        uidsToSave = editedUids;
      }
      if (uidsToSave.length > 0) {
        try {
          props.setIsLoading(true);
          const sitesToSave = cloneDeep(props.data.filter((item) => uidsToSave.includes(item.uid)));
          const EprId = selectedFilters.EPR === 'global' ? null : selectedFilters.EPR;
          let protocolItemId: any = null;
          if (selectedFilters.EPR === 'global') {
            protocolItemId = selectedProtocolItemId;
          }
          sitesToSave.forEach((item: any) => {
            item.isPublished = isPublish;
            item.siteDetailTarget = selectedEpr;
            item.EprId = EprId;
            item.ProtocolItemId = protocolItemId;
            item.radiusInMiles = item?.radiusInMiles || '100';
            item.threshold = item?.threshold || '100';
            item.priority = item?.priority || '0';
            item.contactEmail = `${item?.contactEmail || ''}`;
            item.studyStaffEmails = `${item?.studyStaffEmails || ''}`;
            item.UpdatedBy = userName;
          });
          const saveSitesOperation = await saveSites(sitesToSave);
          if (saveSitesOperation) {
            props.setEditedUids([]);
            // Refetch Data
            if (selectedProtocol && selectedFilters.language) {
              const refetchedData = await getSites(
                selectedProtocol,
                selectedFilters.language,
                selectedFilters.EPR
              );
              props.setData(refetchedData);
              props.setCloneData(refetchedData);
              props.setRowSelection({});
            }
            alert('Sites Saved');
          }
          props.setIsLoading(false);
        } catch (_error) {
          props.setIsLoading(false);
        }
      }
    } else {
      alert('No Sites selected or edited to Save');
    }
  };

  const onPublish = async () => {
    try {
      const selectedRowIds = Object.keys(rowSelection);
      if (selectedRowIds.length < 1) {
        alert('No sites are selected. Please select the Sites to be Published');
        return;
      }
      if (Array.isArray(props.data)) {
        const approvedRows = props.data.filter((item) => {
          // @ts-ignore
          return item.source === 'staging' && selectedRowIds.includes(item.uid);
        });
        //Add no of sites selected
        const confirmPopup = confirm(
          `Publishing ${approvedRows.length} Sites to Sitecore. Changes will be visible on the Website. Proceed?`
        );
        if (confirmPopup && approvedRows.length > 0) {
          props.setIsLoading(true);
          const EprId = selectedFilters.EPR === 'global' ? null : selectedFilters.EPR;
          let protocolItemId: any = null;
          if (selectedFilters.EPR === 'global') {
            protocolItemId = selectedProtocolItemId;
          }
          approvedRows.forEach((item: any) => {
            item.isPublished = true;
            item.siteDetailTarget = selectedEpr;
            item.EprId = EprId;
            item.radiusInMiles = item?.radiusInMiles || '100';
            item.threshold = item?.threshold || '100';
            item.priority = item?.priority || '0';
            item.contactEmail = item?.contactEmail || '';
            item.studyStaffEmails = item?.studyStaffEmails || '';
            item.ProtocolItemId = protocolItemId;
            item.UpdatedBy = userName;
          });
          const publishSitesOperation = await saveSites(approvedRows);
          if (publishSitesOperation) {
            // Refetch Data
            if (selectedProtocol && selectedFilters.language) {
              const refetchedData = await getSites(
                selectedProtocol,
                selectedFilters.language,
                selectedFilters.EPR
              );
              await sleep(500);
              props.setCloneData(refetchedData);
              props.setData(refetchedData);
              props.setRowSelection({});
            }
            props.setIsLoading(false);
            alert(`${approvedRows.length} Sites Published`);
          }
          props.setIsLoading(false);
        } else {
          props.setIsLoading(false);
          alert('Only the sites under Staging can be published');
        }
      } else {
        return;
      }
    } catch (error) {
      props.setIsLoading(false);
      alert('Error Publishing Sites');
    }
  };

  /**
   * Translate selected rows.
   */
  const onTranslateClick = async () => {
    const lang = selectedFilters.language;
    try {
      let uidsToFilter: string[] = [];
      const isRowsSelected = Object.keys(rowSelection).length > 0;
      if (isRowsSelected) {
        uidsToFilter = Object.keys(rowSelection);
      }
      if (uidsToFilter.length > 0) {
        const sitesToTranslate = props.data.filter((item) => uidsToFilter.includes(item.uid));
        const fieldOptionsToCheck =
          selectedFilters.EPR === 'global' ? fieldOptions : eprFieldOptions;
        const formatedSites = formatSitesForTranslation(
          sitesToTranslate,
          lang,
          fieldOptionsToCheck
        );
        props.setIsLoading(true);
        const translateSitesOperation = await translateSites(formatedSites);
        if (translateSitesOperation.sitesDataRequest) {
          props.setRowSelection({});
          const clonedTranslatedRows: ISiteMngData[] = [];
          props.data.forEach((item) => {
            translateSitesOperation.sitesDataRequest.forEach((resObj: any) => {
              if (item.uid === resObj.id) {
                const newSmaRowId = uuidv4();
                const newUid = `${newSmaRowId}_${SOURCE_SMA}`;
                const clonedItem = cloneDeep(item);
                const newItem = { ...clonedItem, ...resObj };
                delete newItem.id;
                newItem.subRows = [];
                newItem.smaRowId = newSmaRowId;
                newItem.source = SOURCE_SMA;
                newItem.uid = newUid;
                newItem.language = selectedFilters.language;
                clonedTranslatedRows.push(newItem);
                props.setEditedUids((arr) => [...arr, newUid]);
              }
            });
          });
          props.setData([...clonedTranslatedRows, ...props.data]);
          props.setIsLoading(false);
        }
        props.setIsLoading(false);
      } else {
        props.setIsLoading(false);
        alert('No Sites selected to Translate');
      }
    } catch (_error) {
      props.setIsLoading(false);
    }
  };
  const isBtnDisabled = Array.isArray(props.data) && props.data.length < 1;

  return (
    <>
      <TranslateModal
        isOpen={isTranslateOpen}
        setOpen={setIsTranslateOpen}
        handleTranslateClick={onTranslateClick}
      />
      <Box width="50%">
        <Stack spacing={3} direction="row">
          <Button
            variant="contained"
            disabled={isBtnDisabled}
            fullWidth
            onClick={() => onSaveClick(false)}
          >
            Save
          </Button>
          {props.role &&
            (props.role.includes(Roles.ADMIN) || props.role.includes(Roles.APPROVER)) && (
              <Button
                variant="contained"
                disabled={isBtnDisabled}
                fullWidth
                onClick={() => onPublish()}
              >
                Publish
              </Button>
            )}
          {selectedFilters.language !== 'en' && selectedFilters.EPR === EPR_TYPE.Global && (
            <Button
              variant="contained"
              disabled={isBtnDisabled}
              fullWidth
              onClick={() => setIsTranslateOpen(true)}
            >
              Translate
            </Button>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default FooterActions;
