/* eslint-disable @next/next/no-img-element */
import Edit from '@mui/icons-material/Edit';
import Search from '@mui/icons-material/Search';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useRouter } from 'next/router';
import { UIButton } from '../shared/UIButton.styles';
import {
  AlertInfoBox,
  MyProfileBannerContainer,
  MyProfileBannerInfoBox,
  MyProfileBoxWithoutBackground,
} from './MyProfileBanner.style';
// import { MyProfileBannerDataProps } from './MyProfileBanner.type';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideBanner } from '../../features/crBannerSlice';
import { RootState } from '../../store/store';
// import { NextImage } from '@sitecore-jss/sitecore-jss-nextjs';
import { GetStaticComponentProps, constants } from '@sitecore-jss/sitecore-jss-nextjs';
import AutocompleteMultiSelect from '../../components/shared/AutocompleteMultiSelect/AutocompleteMultiSelect';
import { getCommunities, getStringValFromArray } from '../../services/RegisterUserService';
import { fetchConditionOfInterest } from '../../GraphQL/queries/fetchConditionOfInterest.query';
import CRModal from '../../components/CR/CRModal';
import { updateSelectedCOI, updateSelectedCommunities } from '../../features/crProfileSlice';
import { ExtenedLayoutServiceContextInterface } from '../../lib/type/Site';
import { post as cdpPost } from '../../lib/utils/axiosCDPFetcher';
import { formateCOIList } from '../../lib/utils/common';
import MyProfileCommunityCard from './MyProfileCommunityCard';
import { mergeTag } from './common';
import { StCommonTypography } from '../shared/Container/Container.styles';
import * as cdpService from '../../services/CdpService';
import { CDP_EVENTS } from '../../constants';

const updateCommunitiesToCdp = async (selectedCOIList: any[]) => {
  try {
    const conditionOfInterest = getStringValFromArray(selectedCOIList, 'value', 'key');
    const communityList: any[] = getCommunities(selectedCOIList);
    const communities = getStringValFromArray(communityList, 'value', 'key');

    const PROFILE_VERIFY_TOKEN_KEY = 'myProfileVerificationToken';

    const myProfileVerificationToken = sessionStorage.getItem(PROFILE_VERIFY_TOKEN_KEY);
    const data = {
      myProfileToken: myProfileVerificationToken,
      conditionOfInterest: {
        items: conditionOfInterest.items || [],
      },
      communities: {
        items: communities?.items || [],
      },
    };
    const cdpRes = await cdpPost('/api/contacts/UpdateConditionCommunitiesForMyProfile', data);
    if (cdpRes) {
      cdpService.event(CDP_EVENTS.BASE_PROFILE_UPDATED, { reference: 'coi_update' });
    }
    return cdpRes;
  } catch (error) {
    return false;
  }
};

const getSelectedCommunities = (communityData: any) => {
  if (communityData && communityData.length > 0) {
    const communityList: any[] = getCommunities(communityData);
    const communities = getStringValFromArray(communityList, 'value', 'key');
    return communities?.items;
  } else {
    return [];
  }
};

const MyProfileBanner = (props: any): JSX.Element => {
  const theme = useTheme();
  const router = useRouter();
  const dispatch = useDispatch();

  const isBannerShown = useSelector((state: RootState) => state.crBanner.isBannerShown);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const crProfileData = useSelector((state: RootState) => state?.crProfile);
  const preSelectedConditions = crProfileData?.profileData?.conditions;
  const basicDetails = crProfileData?.profileData?.basicDetails;
  const selectedCOI = crProfileData?.selectedCOI || [];
  const noOptionText = props.fields.noOptionText || 'No option found';

  const heading = props?.fields?.heading?.value || '';
  const subHeading = props?.fields?.subHeading?.value || '';
  const communityHeading = props?.fields?.communityHeading?.value || '';
  const customizeLabel = props.fields.customizeLabel;
  const modalHeading = props?.fields?.modalHeading;
  const modalPlaceholderLabel = props?.fields?.modalPlaceholderLabel;
  const modalSubHeading = props?.fields?.modalSubHeading;
  const confirmLabel = props?.fields?.confirmLabel;
  const cancelLabel = props?.fields?.cancelLabel;
  const emailVerificationWarningLabel = props?.fields?.emailVerificationWarningLabel;

  const formatedCOIList: any = formateCOIList(props?.COIList) || [];
  const [addedConditions, setAddedConditions] = useState<any>([]);
  const [savedConditionsList, setSavedConditionsList] = useState<any>([]);
  const [savedCommunitiesList, setSavedCommunitiesList] = useState<any>([]);

  useEffect(() => {
    if (formatedCOIList && formatedCOIList?.length > 0) {
      const savedCommunities: any = [];
      const savedCOIList: any = [];

      preSelectedConditions?.map((conditions: any) => {
        if (
          conditions?.sitecoreID !== '' ||
          conditions?.sitecoreID !== undefined ||
          conditions?.sitecoreId !== null ||
          conditions?.conditionId !== '' ||
          conditions?.conditionId !== undefined ||
          conditions?.conditionId !== null
        ) {
          const coiItem = formatedCOIList?.find((item: any) => item?.id === conditions?.sitecoreId);
          if (coiItem && (coiItem?.category !== '' || coiItem?.category !== undefined)) {
            savedCOIList.push(coiItem);
          }
        }
      });

      savedCOIList &&
        savedCOIList?.forEach((conditions: any) => {
          const communitiesList = conditions?.communities;
          if (communitiesList && communitiesList?.length > 0) {
            communitiesList?.map((item: any) => {
              const temp = {
                sitecoreId: item?.sitecoreId,
                key: item?.key || '',
                value: item?.value || '',
                description: item?.description || '',
                icon: item?.icon || '',
                siteCoreCommunityId: item?.siteCoreCommunityId || '',
              };
              savedCommunities.push(temp);
            });
          }
        });

      const uniqueCommunityList = savedCommunities?.reduce((list: any, currentItem: any) => {
        if (!list.find((item: any) => item.value === currentItem.value)) {
          list.push(currentItem);
        }
        return list;
      }, []);

      setSavedConditionsList(savedCOIList);
      setSavedCommunitiesList(uniqueCommunityList);

      //Updating redux and local state based on userProfile
      dispatch(updateSelectedCOI(savedCOIList));
      setAddedConditions(savedCOIList);
    }
  }, [preSelectedConditions]);

  useEffect(() => {
    if (formatedCOIList && formatedCOIList?.length > 0) {
      const savedCommunities: any = [];
      const savedCOIList =
        addedConditions &&
        addedConditions?.map((conditions: any) => {
          return formatedCOIList?.find((item: any) => item?.id === conditions?.sitecoreId);
        });

      savedCOIList &&
        savedCOIList?.forEach((conditions: any) => {
          const communitiesList = conditions?.communities;
          if (communitiesList && communitiesList?.length > 0) {
            communitiesList?.map((item: any) => {
              const temp = {
                sitecoreId: item?.sitecoreId,
                key: item?.key || '',
                value: item?.value || '',
                description: item?.description || '',
                icon: item?.icon || '',
                siteCoreCommunityId: item?.siteCoreCommunityId || '',
              };
              savedCommunities.push(temp);
            });
          }
        });

      const uniqueCommunityList = savedCommunities.reduce((list: any, currentItem: any) => {
        if (!list.find((item: any) => item.value === currentItem.value)) {
          list.push(currentItem);
        }
        return list;
      }, []);
      setSavedConditionsList(savedCOIList);
      setSavedCommunitiesList(uniqueCommunityList);
    }
    if (addedConditions && addedConditions?.length > 0) {
      const communities = getSelectedCommunities(addedConditions);
      dispatch(updateSelectedCommunities(communities));
    } else {
      dispatch(updateSelectedCommunities([]));
    }
  }, [addedConditions]);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleContinue = async () => {
    const response = await updateCommunitiesToCdp(addedConditions);
    if (response?.status === 200) {
      dispatch(updateSelectedCOI(addedConditions));
      if (addedConditions && addedConditions?.length > 0) {
        dispatch(updateSelectedCommunities(getSelectedCommunities(addedConditions)));
        handleCloseModal();
      } else {
        dispatch(updateSelectedCommunities([]));
        handleCloseModal();
      }
    }
    handleCloseModal();
  };

  const handleCancel = () => {
    setAddedConditions(selectedCOI);
    handleCloseModal();
  };

  return (
    <Box>
      <MyProfileBannerContainer>
        <MyProfileBoxWithoutBackground>
          {isBannerShown && (
            <AlertInfoBox>
              <Alert
                icon={<LockOpenIcon fontSize="inherit" />}
                variant="filled"
                severity="warning"
                onClose={() => {
                  dispatch(hideBanner());
                }}
                sx={{ color: '#fff !important' }}
              >
                {emailVerificationWarningLabel?.value}
              </Alert>
            </AlertInfoBox>
          )}
          <MyProfileBannerInfoBox>
            {basicDetails && (
              <StCommonTypography>{mergeTag(heading, basicDetails)}</StCommonTypography>
            )}
            <StCommonTypography variant="body2">{subHeading}</StCommonTypography>
          </MyProfileBannerInfoBox>

          <Grid container>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                gap: 1,
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              {savedConditionsList?.map((item: any, i: number) => {
                const isAlignLeft = item?.fields?.iconLeft?.value || false;
                const url = props?.fields?.trialSearchLink?.value?.href
                  ? `${props?.fields?.trialSearchLink?.value?.href}#q=${item?.key}`
                  : '#';

                return (
                  <UIButton
                    key={i}
                    variant={isAlignLeft ? 'outlined' : 'contained'}
                    endIcon={!isAlignLeft ? <Search /> : ''}
                    startIcon={isAlignLeft ? <Edit /> : ''}
                    sx={{
                      minWidth: 'unset',
                      p: '5px 24px',
                      filter: 'unset',
                      boxShadow: 'unset',
                      fontFamily: 'Proxima Nova',
                    }}
                    onClick={() => router.push(url)}
                  >
                    {item?.description}
                  </UIButton>
                );
              })}
              <UIButton
                variant={'outlined'}
                startIcon={<Edit />}
                sx={{ minWidth: 'unset', p: '5px 24px', fontFamily: 'Proxima Nova' }}
                onClick={handleOpenModal}
              >
                {customizeLabel?.value}
              </UIButton>
            </Box>
          </Grid>

          <MyProfileBannerInfoBox>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <StCommonTypography variant="body2">{communityHeading}</StCommonTypography>
            </Box>
            <Grid id="selectedcommunity-list-container" item container>
              {savedCommunitiesList?.map((community: any, index: number) => {
                // const descriptionFields = getFieldsByName(community, 'description');
                // const iconsFields = getFieldsByName(community, 'icon');
                // const imgSrc = iconsFields?.jsonValue?.value?.src || '';
                // const title = descriptionFields?.jsonValue?.value || 'Test';
                const descriptionFields = community?.description;
                const iconsFields = community?.icon;
                const imgSrc = iconsFields?.src || '';
                const title = descriptionFields || 'Test';
                return (
                  <MyProfileCommunityCard
                    key={index}
                    title={title}
                    imageSrc={imgSrc}
                    isMobile={isMobile}
                    communityItem={community?.siteCoreCommunityId}
                    // communityCardColor={iconColor?.value}
                    // onClickHandler={onClickHandler}
                    // onKeyUp={(e, communityItem) => {
                    //   if (e.key === 'Enter') {
                    //     onClickHandler(communityItem);
                    //   }
                    // }}
                    sxCommunityCardProps={{
                      background: theme.palette.neutral0.main,
                      border: `1px solid ${theme.palette.neutral4.main}`,
                      borderRadius: '10px',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      padding: '1rem',
                      cursor: 'pointer',
                      flexWrap: 'nowrap !important',
                      '&:hover': {
                        border: `1px solid ${theme.palette.primary.main}`,
                      },
                    }}
                    sxCommunityCardTitleProps={{
                      fontSize: '1.125rem',
                      fontWeight: 500,
                      [theme.breakpoints.down('sm')]: {
                        marginLeft: '1rem',
                      },
                      [theme.breakpoints.up('sm')]: {
                        marginTop: '0.5rem',
                      },
                    }}
                  />
                );
              })}
            </Grid>
          </MyProfileBannerInfoBox>
        </MyProfileBoxWithoutBackground>
      </MyProfileBannerContainer>

      <CRModal
        open={openModal}
        handleClose={handleCancel}
        handleContinue={handleContinue}
        handleCancel={handleCancel}
        title={modalHeading?.value}
        confirmButton={{ name: confirmLabel?.value }}
        cancelButton={{ name: cancelLabel?.value }}
      >
        <Grid sx={{ width: '100%' }}>
          <AutocompleteMultiSelect
            limitTags={2}
            id={'conditions-of-interest'}
            options={formatedCOIList}
            noOptionText={noOptionText?.value}
            label={modalSubHeading?.value}
            placeholder={modalPlaceholderLabel?.value}
            onChangeHandler={(newValue: any) => {
              setAddedConditions(newValue);
            }}
            value={addedConditions}
            maxVisibleOptions={2}
          />
        </Grid>
      </CRModal>
    </Box>
  );
};
export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  const context = layoutData?.sitecore?.context as ExtenedLayoutServiceContextInterface;
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';

  if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
    return null;
  }
  const COIList = await fetchConditionOfInterest(language);

  return {
    COIList,
  };
};

export default MyProfileBanner;
