import type { AppProps } from 'next/app';
import { useEffect, useState } from 'react';
import { I18nProvider } from 'next-localization';
import { SitecorePageProps } from 'lib/page-props';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { getTheme } from '../theme/defaultTheme';
import { Provider } from 'react-redux';
import { store } from '../store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ExtenedLayoutServiceContextInterface } from '../lib/type/Site';
import 'assets/app.css';
import '../styles/global.scss';
import { CookiesProvider } from 'react-cookie';
import { useRouter } from 'next/router';
import { getCookie } from '../lib/utils/enrollmentUtil';
import { getQuery } from '../lib/utils/common';

function App({ Component, pageProps }: AppProps<SitecorePageProps>): JSX.Element {
  const { dictionary, ...rest } = pageProps;
  const sitecoreContext = pageProps?.layoutData?.sitecore?.context;
  const theme = getTheme(sitecoreContext || {});
  const [queryClient] = useState(() => new QueryClient());
  const context = rest?.layoutData?.sitecore?.context as ExtenedLayoutServiceContextInterface;
  const siteKey = atob((context?.siteSettings?.googleReCaptchaSitekey?.value as string) || '');
  const router = useRouter();
  const disableGtmPreviewMode = () => {
    const gtmDebugElement = document.getElementById('_GTM_debug');
    if (gtmDebugElement) {
      gtmDebugElement.style.display = 'none';
    }
  };
  useEffect(() => {
    disableGtmPreviewMode();
  }, []);
  useEffect(() => {
    const preferredLangauag = getCookie('language') || 'en';
    const { pathname, asPath, query } = router;
    if (pageProps?.locale !== preferredLangauag) {
      router.push({ pathname, query: getQuery(query) }, asPath, { locale: preferredLangauag });
    }
  }, []);

  return (
    // Use the next-localization (w/ rosetta) library to provide our translation dictionary to the app.
    // Note Next.js does not (currently) provide anything for translation, only i18n routing.
    // If your app is not multilingual, next-localization and references to it can be removed.
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={store.__persistor} loading={null}>
          {() => (
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <I18nProvider lngDict={dictionary} locale={pageProps?.locale}>
                <>
                  <GoogleReCaptchaProvider
                    reCaptchaKey={siteKey}
                    scriptProps={{
                      async: false,
                      defer: true,
                      appendTo: 'head',
                      nonce: undefined,
                    }}
                  >
                    <CookiesProvider defaultSetOptions={{ path: '/' }}>
                      <Component {...rest} />
                    </CookiesProvider>
                  </GoogleReCaptchaProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </>
              </I18nProvider>
            </ThemeProvider>
          )}
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
